<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/bill/compreQuery"
    downloadResource="/bill/export"
    :downloadParams="{exportFlag: 'COMPRE'}"
    :settingButtonList="['refresh', 'download']"
    :filterFields="filterFields"
    :columns="columns"
    :defaultSortBy="[{prop: 'billMonth', order: 'desc'}]"
    :operateButtonList="operateButtonList"
    :toolbarButtonList="toolbarButtonList"
    :operationWidth="140"
    :cellClassNameFunc="billPayStatusClassFunc"
    @rowDblClick="handleRowDblClick")
  #print-container
</template>

<script>
import { mapActions } from 'vuex'
import { billPayStatusClassFunc } from '@/utils/common.js'
import { hiprint, disAutoConnect } from 'vue-plugin-hiprint'
import { calcTemplate } from './config'
import $ from 'jquery'

export default {
  name: 'BillQuery',
  data () {
    const actionRights = this.$getPageActions([
      { name: 'delete', url: '/billManage/billQuery/delete' },
      { name: 'invoice', url: '/billManage/billQuery/invoice' },
      { name: 'invoiceCancel', url: '/billManage/billQuery/invoiceCancel' },
      { name: 'detail', url: '/billManage/billQuery/detail' },
      { name: 'print', url: '/billManage/billQuery/print' }
    ])
    return {
      showTable: false,
      billPayStatusClassFunc,
      // 表格筛选表单配置 用户名称/水厂/抄表员/账单产生日期/缴费日期
      filterFields: [{
        name: 'usewaterCode',
        label: '用水户号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerCode',
        label: '用户编号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerName',
        label: '用户姓名',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          multiple: true,
          collapseTags: true,
          clearable: true,
          filterable: true,
          relation: 'waterWorks'
        }
      }, {
        name: 'billMonth',
        label: '账期',
        form: {
          tag: 'date',
          dateType: 'monthrange',
          valueFormatType: 'yyyy-MM',
          timeType: 'YYYY-MM'
        }
      }, {
        name: 'payTime',
        label: '缴费日期',
        form: {
          tag: 'date',
          dateType: 'daterange'
        }
      }],
      // 表格列配置
      columns: [
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 100 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'billPayStatus', label: '缴费状态', relation: 'billPayStatus', render: { type: 'select' } },
        { name: 'payType', label: '缴费方式', relation: 'payType', render: { type: 'select' } },
        { name: 'payTime', label: '缴费日期', minWidth: 120, render: { type: 'date' } },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' },
        { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } },
        { name: 'invoice', label: '已开票', render: { type: 'yesOrNo' } }
      ],
      toolbarButtonList: [],
      operateButtonList: [{
        label: '冲销',
        name: 'writeOff',
        type: 'text',
        textType: 'danger',
        showabled: actionRights.delete,
        disabled: (row) => {
          return row.billPayStatus === 'UNPAY'
        },
        func: this.handleWriteOff
      },
      {
        label: '打印',
        name: 'print',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.print,
        func: ({ data }) => {
          return new Promise((resolve, reject) => {
            const hiprintTemplate = new hiprint.PrintTemplate({
              template: calcTemplate
            })
            this.$get({
              url: `/bill/print?billId=${data.id}`
            }).then(res => {
              if (!res) {
                reject(res)
                return
              }
              // 打印
              const msg = '备注：1、本通知不得涂改。\n2、请于收到本通知后10个工作日内缴纳水费，逾期未缴者，将收取滞纳金恢复供水产生的费用将由用水单位支付。\n3、非居民用水实行超定额累进加价制度的，按《北京市非居民计划用水和定额管理暂行办法》，超过定额用水量时，除按实际用水量正常交纳水费外，对超出定额用水量20%（含）以下、20%至40%（含）、40%以上的部分，分别按照水费的1倍、2倍、3倍加征水费。'
              res.data.msg = msg
              hiprintTemplate.print(res.data)
              resolve(true)
            }).catch(reject)
          })
        }
      },
      {
        label: '详情',
        name: 'check',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: ({ data }) => {
          this.$router.push({
            name: 'billDetail',
            params: {
              billId: data.id
            }
          })
        }
      }],
      showInvoiceDialog: false,
      invoiceValue: {}
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    renderRelation () {
      this.getRelations(['billPayStatus', 'payType', 'waterWorks', 'invoice'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    handleRowDblClick (row) {
      const detailButton = this.operateButtonList[1]
      detailButton.showabled && detailButton.func({ data: row })
    },
    handleWriteOff ({ data }) {
      return new Promise((resolve, reject) => {
        if (data.invoice) {
          const msg = '发票已开具，无法冲销，请先作废发票！'
          this.$message.warning(msg)
          reject(msg)
          return msg
        }
        this.$confirm('是否确认冲销', '提示')
          .then(() => {
            this.$patch({
              url: `/bill/writeOff/${data.id}`
            })
              .then(res => {
                if (!res) {
                  reject(res)
                } else {
                  this.$message.success('冲销成功')
                  this.getData()
                  resolve('success')
                }
              })
              .catch(reject)
          })
          .catch(reject)
      })
    }
  },
  created () {
    if (!window.$) window.jquery = window.$ = $
    this.renderRelation()
  },
  mounted () {
    disAutoConnect()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  position: relative
  padding-right: 8px
</style>
